<div class="flex flex-col justify-start items-stretch">
  <div class="flex flex-col" *ngIf="isLoading && !data?.length">
    <span>Loading</span>
    <mat-progress-bar mode="buffer"></mat-progress-bar>
  </div>

  <mat-progress-bar *ngIf="isBusy" mode="buffer"></mat-progress-bar>

  <div [ngClass]="{'hidden': isLoading || !data?.length || !hasFilter}">
    <mat-form-field class="w-full" appearance="outline">
      <input type="text" matInput [formControl]="filterControl" placeholder="Filter.." autocomplete="off"/>
    </mat-form-field>
  </div>

  <div style="overflow: auto" class="flex">
    <table class="flex-1" mat-table [dataSource]="dataSource" matSort>
      <ng-container *ngFor="let column of columns" matColumnDef="{{ column.identifier }}">
        <th style="padding: 5px" mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ column.displayName ?? column.identifier }}
        </th>
        <td style="padding: 5px" mat-cell *matCellDef="let element">
          <span *ngIf="column.format === 'date'">{{ element[column.identifier] | date : 'MMM dd, yyyy' }}</span>
          <span *ngIf="column.format === 'datetime'">
            {{element[column.identifier] | date : 'MMM dd, yyyy HH:mm'}}
          </span>
          <span *ngIf="column.format === 'number'">{{ element[column.identifier] | number : '0.2' }}</span>
          <span *ngIf="column.format === 'text'">{{ element[column.identifier] }}</span>
          <button
            *ngIf="column.format === 'action'"
            mat-icon-button
            color="primary"
            title="{{ column.toolTip ?? 'action' }}"
            [disabled]="isBusy"
            (click)="actionClick(element)"
          >
            <mat-icon aria-hidden="true">{{ column.iconName ?? 'home' }}</mat-icon>
          </button>
          <span *ngIf="!column.format">{{ element[column.identifier] }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        (click)="click(row)"
        (dblclick)="doubleClick(row)"
        mat-row
        [class.selection]="hasSelection"
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>

  <div *ngIf="!isLoading && !data?.length" class="mt-4 text-center">No data to display</div>

  <mat-paginator
    [ngClass]="{'hidden':!hasPagination}"
    #paginator
    [pageIndex]="currentPage"
    [pageSize]="10"
    [pageSizeOptions]="[10, 20, 50, 100]"
  >
  </mat-paginator>
</div>
