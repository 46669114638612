import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {DataTableService, FilterHistory} from './data-table.service';
import {
  AfterViewInit,
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent<T> implements OnInit, AfterViewInit, OnDestroy {
  @Output() onClick = new EventEmitter<T>();
  @Output() onDoubleClick = new EventEmitter<T>();
  @Output() onActionClick = new EventEmitter<T>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<T>();
  filterControl = new FormControl<string>('');
  currentPage = 0;

  private _filterChangesSub: Subscription;
  private _currentRoute: string;
  private _filterHistory: FilterHistory;
  private _data: T[];
  get data(): T[] {
    return this._data;
  }

  set data(data: T[]) {
    this._data = data;
    this.dataSource.data = this._data;
    this._applyFilters();
    this.isLoading = false;
  }

  columns: TableColumn<T>[];
  displayedColumns: string[];
  isLoading: boolean;
  isBusy: boolean;
  hasFilter: boolean;
  hasPagination: boolean;
  hasSelection: boolean;

  constructor(
    private _dataTableService: DataTableService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._currentRoute = this._router.url;
  }

  ngOnDestroy(): void {
    if (this.filterControl.value.length || this.paginator.pageIndex !== 0) {
      this._dataTableService.remember(
        this._currentRoute,
        this.filterControl.value,
        this.paginator.pageIndex
      );
    }

    this._filterChangesSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this._filterHistory = this._dataTableService.getFilter(
      this._currentRoute
    );
    this._filterChangesSub = this.filterControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((x) => this.filter(x));
  }

  initialize(
    columns: TableColumn<T>[],
    options: DataTableOptions = null,
    setAsLoading: boolean = true
  ) {
    this.columns = columns;
    this.displayedColumns = this.columns.map((x) =>
      x.identifier.toString()
    );

    this.hasFilter = options?.hasFiltering ?? true;
    this.hasPagination = options?.hasPagination ?? true;
    this.hasSelection = options?.hasSelection ?? true;

    if (this.hasPagination) {
      this.dataSource.paginator = this.paginator;
      this._applyFilters();
    }

    if (setAsLoading) this.isLoading = true;
  }

  filter(filterString: string) {
    if (filterString == null) return;
    const filter = filterString.trim().toLowerCase();
    this.dataSource.filter = filter;
  }

  click(data: T) {
    if (this.hasSelection) this.onClick.emit(data);
  }

  doubleClick(data: T) {
    if (this.hasSelection) this.onDoubleClick.emit(data);
  }

  private _applyFilters() {
    if (!this._filterHistory) return;
    const pageIndex = this._filterHistory.pageIndex;
    const filterString = this._filterHistory.filter;

    if (pageIndex != null) this.currentPage = pageIndex;

    if (filterString != null) {
      this.filterControl.setValue(filterString);
    }
  }

  actionClick(data: T) {
    this.onActionClick.emit(data);
  }
}

type TableColumn<T> = {
  identifier: keyof T,
} & ({
  format?: 'text',
  displayName?: string,
} | {
  format?: 'number',
  displayName?: string,
} | {
  format?: 'date',
  displayName?: string,
} | {
  format?: 'datetime',
  displayName?: string,
} | {
  format?: 'action',
  iconName: string,
  toolTip: string,
})

export type DataTableOptions = {
  hasSelection?: boolean;
  hasFiltering?: boolean;
  hasPagination?: boolean;
}
