import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.css']
})
export class UserInputComponent implements OnInit {
    form: UntypedFormGroup;

    constructor(private _dialogRef: MatDialogRef<UserInputComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UserInputComponentData,
        private _fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.form = this._fb.group({
            response: ['']
        });
    }

    submit() {
        this._dialogRef.close(this.form.get('response').value);
    }
}

export class UserInputComponentData {
    label?: string;
}
