import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html'
})
export class AutocompleteComponent implements OnInit, OnDestroy {
    @ViewChild('input') input: MatInput;

    filteredData = new Subject<string[]>();
    valueChangesSub: Subscription;
    isLoading = false;
    control: AbstractControl = new UntypedFormControl();
    placeHolder = 'Input';

    constructor() {}

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.data = null;
        this.valueChangesSub?.unsubscribe();
    }

    private _data: string[];
    get data(): string[] {return this._data;};
    set data(data: string[]) {
        this._data = data;
        this.isLoading = false;
    }

    initialize(control: AbstractControl, placeHolder: string = 'Input', setLoading: boolean = true) {
        this.control = control;
        if(setLoading) this.isLoading = true;
        this.placeHolder = placeHolder;

        this.valueChangesSub = this.control.valueChanges
        .pipe(
            debounceTime(300),
            distinctUntilChanged(),
            map(value => typeof value === 'string' ? value : value?.display)
        ).subscribe(value => {
            this._filter(value);
        });
    }

    focus() {
        this.input?.focus();
    }

    private _filter(filterString: string): string[] {
        if(filterString == null) return;
        if(this.data == null) return;
        const filter = filterString.trim().toLowerCase();
        this.filteredData.next(this.data.filter(x => x.toLowerCase().includes(filter)));
    }
}
