import { MatPaginator } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataTableService {
    filterHistory: FilterHistory[] = [];

    constructor() {}

    remember(route: string, filter: string, pageIndex?: number) {
        if (!filter?.length && pageIndex == null) return;

        const existing = this.filterHistory.find((x) => x.route === route);
        if (existing != null) {
            existing.filter = filter;
            existing.pageIndex = pageIndex;
        } else {
            this.filterHistory.push({
                route: route,
                filter: filter,
                pageIndex: pageIndex
            });
        }
    }

    getFilter(route: string): FilterHistory {
        const filter = this.filterHistory.find((x) => x.route === route);
        return filter;
    }
}

export class FilterHistory {
    route: string;
    pageIndex: number;
    filter: string;
}
