<mat-form-field style="width: 100%" appearance="outline">
    <mat-progress-bar mode="buffer" *ngIf="isLoading" ></mat-progress-bar>
    <mat-label>{{placeHolder}}</mat-label>
    <input type="text"
           matInput
           #input="matInput"
           [formControl]="control"
           [matAutocomplete]="auto">
    <mat-autocomplete autoActiveFirstOption  #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredData | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
