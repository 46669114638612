import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {MaterialModule} from './material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {AutocompleteComponent} from './autocomplete/autocomplete.component';
import {CredentialInputComponent} from './credential-input/credential-input.component';
import {UserInputComponent} from './user-input/user-input.component';
import { DataTableComponent } from './data-table/data-table.component';

@NgModule({ exports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        DataTableComponent,
        AutocompleteComponent,
        CredentialInputComponent,
        UserInputComponent
    ],
    declarations: [
        DataTableComponent,
        AutocompleteComponent,
        CredentialInputComponent,
        UserInputComponent
    ], imports: [CommonModule,
        ReactiveFormsModule,
        MaterialModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule {
}
